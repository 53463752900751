<template>
  <div class="log-popup" style="overflow-x: hidden;">
    <div class="row">
      <div class="col-xl-12">
        <b-overlay :show="!items" variant="transparent" opacity="1" blur="5px" rounded="sm">
          <div class="card mb-0">
            <div class="card-body bg-black text-white">
              <div v-if="items.length > 0">
                <div v-for="item in items" :key="item.id">
                  <span class="ct-padding-right-100">{{ item.created }}</span>
                  <span class="ct-padding-right-100">[{{ item.chargeBoxId }}]</span>
                  <span class="ct-padding-right-100">[{{ item.msgType }}]</span>
                  <span>{{ item.msg }}</span>
                </div>
              </div>
              <div v-else style="height: 92vh">
                <span>{{ $t('chargeBox.noLog') }}</span>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from '@/auth-api';
import i18n from '@/i18n';

export default {
  data() {
    return {
      boxId: null,
      boxName: null,
      searchConditions: [],
      pageLimit: 500,
      items: null,
      realLog: true,
      count: 0
    }
  },
  mounted() {
    const vm = this;

    let query = window.location.search;
    let param = new URLSearchParams(query);
    vm.boxId = param.get('id');

    vm.realLog = setInterval(vm.getItems, 3000);
    window.addEventListener('scroll', function() {
      vm.detectBottom();
    })
  },
  methods : {
    getItems() {
      const vm = this;
      const _url = '/onmapi/view/OcppLog/logViewList';

      vm.searchConditions = [{searchKey: 'chargeBoxId', searchValue: vm.boxId}];

      http.post(_url, {
        page: 1,
        limit: vm.pageLimit,
        searchConditions: vm.searchConditions
      }).then(res => {
        vm.items = res.data.items;
      }).then(res => {
        if (vm.count === 0 && vm.items.length > 0) {
          window.scrollTo({ top: 99999, behavior: 'instant'});
          vm.count = 1;
        }
      }).catch(err => {
        console.error('Log List Error :: ', err)
      });
    },
    close() {
      const vm = this;
      clearInterval(vm.realLog);

      vm.items = null;
      vm.count = 0;
    },
    detectBottom() {
      let scrollTop = document.documentElement.scrollTop;
      let innerHeight = window.innerHeight;
      let scrollHeight = document.body.scrollHeight;

      if (scrollTop + innerHeight >= scrollHeight) {
        return true;
      } else {
        window.scrollTo({ top: scrollTop, behavior: 'instant'});
        return false;
      }
    }
  }
}
</script>
